@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.container {
	background-color: $black;
	color: $white;
	display: flex;
	width: 100%;
	min-height: 100%;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	padding-top: 1.5rem;
	padding-bottom: 2.625rem;
	@include md-down {
		padding: 1.5rem 1rem 2rem 1rem;
		gap: 1.5rem;
		height: auto;
	}
}

.progress {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1.5rem;
}
.progress_bar {
	background: #363636;
	max-width: 734px;
	width: 100%;
	height: 6px;
	display: flex;
	align-items: stretch;
}
.progress_bar_segment {
	background: transparent;
	border-radius: 3px;
	height: 100%;
	flex: 1;
}
.progress_bar_active {
	background: #fff;
}
.progress_text {
	@include body2;
	color: #a0a0a0;
}
.answer {
	color: var(--Neutral-Gray-4, #636363);
	border: 1px solid var(--Neutral-Gray-4, #636363);
	cursor: pointer;
	padding: 1rem 1.5rem;
	text-align: center;
	border-radius: $br-pill;
	width: 100%;
	height: 3.625rem;

	> span {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 10px;
	}

	@include headline9;
	@include sm-down {
		padding: 1rem 1.25rem;
		@include headline9;
		font-size: 1rem;
		max-width: 260px;
	}
}
.selected_answer {
	color: var(--Neutral-White, #fff);
	border: 1px solid #fff;
}
.answers {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 1rem;
	max-width: 420px;
	@include sm-down {
		max-width: 260px;
	}
	@include md-up {
		@media (max-height: '750px') {
			display: grid;
			grid-template-columns: 1fr 1fr;
			gap: 1rem;
			max-width: 840px;
			flex-wrap: wrap;
		}
	}
}

.answers_rows {
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 1rem;
	max-width: 840px;
	flex-wrap: wrap;

	@include sm-down {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 1rem;
		max-width: 420px;
		@media (max-height: '700px') {
			display: grid;
			grid-template-columns: 1fr 1fr;
			row-gap: 1.5rem;
			column-gap: 0.625rem;
			max-width: 840px;
			flex-wrap: wrap;
		}
	}
}
.question_container {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 4.25rem;
	width: 100%;

	@include md-down {
		gap: 2.75rem;
	}
}

.question {
	width: 25ch;
	text-align: center;
	@include headline4;
	@include sm-down {
		@include headline7;
		font-size: 1.75rem;
		font-weight: 400;
		width: 21ch;
		max-width: 100%;
	}
}

.question_header {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1.5rem;
	width: 100%;
}
.reminder_text {
	text-align: center;
	color: #d3d3d3;
	@include body1;
	@include sm-down {
		@include body4;
	}
}
.next_button {
	width: 100%;
}
.button_container {
	margin-top: 2.625rem;
	width: 200px;

	@include md-down {
		margin-top: 1.5rem;
	}
	@include sm-down {
		width: 100%;
		max-width: 100%;
		position: sticky;
		bottom: 1.5rem;
	}
}

.hidden {
	opacity: 0;
	cursor: default;
}
.checkmark {
	@include sm-down {
		@media (max-height: '700px') {
			display: none;
		}
	}
}
