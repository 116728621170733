@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.email_capture_wrapper {
	margin: 0 auto;

	h4 {
		font-size: 14px;
		text-align: center;
		line-height: 1.4;
		margin: 0;
		margin-bottom: 15px;
	}

	.supertitle {
		color: $brand-eight-blue;
		text-align: center;
		font-size: 12px;
		font-weight: 500;
		text-transform: uppercase;
		letter-spacing: 2px;
	}

	h3 {
		@include headline3;
		@include sm-down {
			@include headline6;
			text-align: center;
			margin-bottom: 2rem;
			max-width: 290px;
			margin-left: auto;
			margin-right: auto;
		}
		text-align: center;
		margin-bottom: 2rem;
	}

	p {
		@include body1;
		color: #636363;
		font-size: 14px;
		text-align: center;
		max-width: 35ch;
		//margin: 0 auto 16px;
		line-height: 140%;
		@include sm-down {
			@include body4;
			color: #636363;
		}
	}

	div {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
	}

	@include sm-down {
		text-align: center;
	}

	.email_form {
		margin: 10px auto 0 auto;
		width: 360px;
		@include sm-down {
			max-width: 100%;
		}
		input {
			width: 100%;
			font-size: 16px;
			padding: 10px;
			min-height: 30px;
			border: none;
			border-bottom: 1px solid #636363;

			&:focus {
				outline: none;
				border-color: #000;
				box-shadow: 0 1px 0 0 #000;
			}
		}

		button {
			width: 100%;
			margin: 15px auto;
		}

		button.submit {
			height: 52px;
			font-size: 16px !important;
		}

		@include sm-down {
			margin: 3rem auto 0 auto;

			button {
				font-size: 13px !important;
			}
		}
	}
	max-width: 600px;
	position: relative;
	@include sm-down {
		max-width: 100%;
	}
}

.subtitle {
	font-size: 18px;
	font-style: normal;
	line-height: 1.4;
	display: block;
	max-width: 40ch;
	margin-left: auto !important;
	margin-right: auto !important;
}

button.button {
	margin: auto;
	text-align: center;
	width: 100%;
	font-size: 14px;
	text-decoration-line: underline;
	color: #8d9098;
}

.submitbutton {
	text-transform: none;
	font-weight: 400;
}

div.disclaimer {
	text-align: center;
	font-size: 11px;
	line-height: 1.4;
	color: #8e9199;
	margin-top: 25px;
}
