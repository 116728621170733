@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.container {
	background-color: $white;
	color: $black;
	max-height: 100%;
	@include sm-up {
		height: 100%;
	}
	@include sm-down {
		max-height: unset;
		height: auto;
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
		min-height: 100vh;
	}
	position: relative;
}
.content {
	background-color: $white;
	color: $black;
	display: flex;
	//height: 100lvh;
	margin: 0 auto;
	max-width: 1595px;
	justify-content: space-between;
	align-items: center;
	padding: 2rem;

	@include sm-down {
		flex-direction: column;
		min-height: 100vh;
		gap: 2rem;
		justify-content: center;
		padding: 1rem 1rem 2.5rem 1rem;
		max-width: 100%;
	}
	// @include sm-down {
	// }
	height: 100%;
	position: relative;
}
.left {
	flex: 0 1 50%;
	display: flex;
	justify-content: center;
	height: 100%;
	position: relative;
	@include sm-down {
		align-items: center;
	}
}
.right {
	flex: 0 1 50%;
	max-width: 100%;
}
.companion_img {
	width: unset !important;
	@include sm-down {
		height: 350px;
	}
	@include xs-only {
		height: 250px;
	}
	// transform: translateZ(0);
	// filter: drop-shadow(0px 4px 50px rgba(0, 0, 0, 0.3));
}
.result_container {
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 600px;
	gap: 2.5rem;
	@include sm-down {
		max-width: 360px;
		gap: 1.5rem;
	}
}

.result_checkbox {
	display: flex;
	align-items: center;
	max-width: 40px;
	max-height: 40px;
	@include sm-down {
		display: none;
	}
}

.result_heading {
	@include headline4;
	text-align: center;
	margin-bottom: 1.5rem;
	@include sm-down {
		@include headline6;
		margin-bottom: 1.5rem;
		max-width: 20ch;
	}
}

.result_text {
	@include body3;
	color: #636363;
	text-align: center;
	@include sm-down {
		@include body4;
	}
}

.result_quote {
	@include body3;
	color: #636363;
	text-align: center;

	@include sm-down {
		@include body4;
	}
}

.result_reason {
	padding: 1.875rem;
	@include body3;
	max-width: 40ch;
	border-radius: 10px;
	text-align: center;
	background: $background-off-white;

	@include sm-down {
		@include body4;
		padding: 1rem;
	}
}

.submitbutton {
	width: min(395px, 100%);
	@include sm-down {
		width: min(360px, 100%);
	}
}
